<template>
    <dashboard-layout>
        <v-card class="mt-4" width="100%">
            <v-card-title>
                <v-row>
                    <v-col>
                        <h3>Choose your equipment</h3>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-form>
                    <v-row>
                        <v-col cols="12" md="5">
                            <v-text-field
                                label="Add your own equipment"
                                :error-messages="newEquipmentErr"
                                v-model="newEquipment"
                            />
                        </v-col>
                        <v-col cols="12" md="2">
                            <v-btn @click="addNewEquipment">
                                Add
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col v-for="eq in equipments" :key="eq.name" cols="12" md="4" lg="3">
                            <v-checkbox
                                v-model="equipmentSelected"
                                :label="eq.name"
                                :value="eq.name"
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-btn @click="save">Save</v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>
    </dashboard-layout>
</template>

<script>
import Equipment from "@/models/Equipment";
import DashboardLayout from "@/layouts/DashboardLayout";
import {mapMutations} from "vuex";

export default {
    name: "users-show",
    components: {DashboardLayout,},
    data: function () {
        return {
            equipments: [],
            equipmentSelected: [],
            newEquipment: null,
            newEquipmentErr: null,
        }
    },
    methods: {
        async save() {
            this.newEquipmentErr = null
            let msg = 'Equipment saved successfully';
            let color = 'success'

            try {
                await window.axios.put('/api/equipment/sync', {
                    equipment: this.$_.map(this.equipmentSelected, eq => eq instanceof Equipment ? eq.name : eq)
                })
            } catch (e) {
                msg = 'Something went wrong, please try again later.'
                color = 'error'
            }

            this.showSnackBar({color: color, timeout: 3000, text: msg})
        },
        addNewEquipment() {
            this.newEquipmentErr = null

            if (!this.newEquipment) {
                this.newEquipmentErr = "Please fill this field"
                return
            }

            let eq = this.$_.find(this.equipments, e => e.name === this.newEquipment)

            if (!eq) {
                eq = new Equipment({name: this.newEquipment})
                this.equipments.push(eq)
            }

            this.equipmentSelected.push(eq.name)
            this.equipmentSelected = [...new Set(this.equipmentSelected)]

            this.newEquipment = null
        },
        async loadData() {
            this.equipments = await Equipment.get();
            this.equipmentSelected = this.$_.map(await Equipment.where('user', this.$auth.user().id).get(), eq => eq.name)
        },
        ...mapMutations(['showSnackBar']),
    },
    async mounted() {
        await this.loadData()
    }
}
</script>

<style scoped>

</style>
